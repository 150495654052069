<template>
  <ImagePreviewMolecule :previewBackground="previewBackground" :previewColor="previewColor" @click="$emit('click')">
    <span v-if="!hasPreviewImage">no image set yet</span>
    <ResponsiveImageAtom v-else :upload="upload" :expectedSizes="['200px']" />
  </ImagePreviewMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

import ResponsiveImageAtom from '../Atoms/ResponsiveImageAtom'

const ImagePreviewMolecule = styled('div')`
  ${flexCenter}
  width: 200px;
  max-width: 100%;
  min-height: 120px;
  border-radius: 0.5rem;
  transition: border-color 0.25s ease;
  color: ${({ previewColor }) => previewColor};
  background: ${({ previewBackground }) => previewBackground};
`

export default {
  props: {
    previewColor: {
      type: String,
      required: true,
    },
    previewBackground: {
      type: String,
      required: true,
    },
    upload: {
      type: Object,
    },
  },
  components: {
    ResponsiveImageAtom,
    ImagePreviewMolecule,
  },
  computed: {
    hasPreviewImage() {
      return this.upload?.type === 'IMAGE'
    },
  },
}
</script>
