<template>
  <ImageManagerMolecule
    :title="title"
    :upload="upload"
    :previewColor="previewColor"
    :previewBackground="previewBackground"
    @file-selected="handleFileSelected"
    @select-upload="$emit('update:uploadId', $event.id)"
  >
    <slot slot="header" name="header" />
    <slot slot="footer" name="footer" />
  </ImageManagerMolecule>
</template>

<script>
import { FlashMessages } from '@common/singletons'

import ImageManagerMolecule from '@/components/Atomic/Molecules/ImageManagerMolecule'

import UPLOAD_MUTATION from '#/graphql/uploads/uploadMutation.gql'
import UPLOAD_QUERY from '#/graphql/uploads/uploadQuery.gql'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    uploadId: {
      type: String,
    },
    previewColor: {
      type: String,
    },
    previewBackground: {
      type: String,
    },
  },
  components: {
    ImageManagerMolecule,
  },
  methods: {
    async handleFileSelected({ target: { files = [] } }) {
      try {
        if (!files.length) {
          return
        }
        this.isUploading = true
        const res = await this.$apollo.mutate({
          mutation: UPLOAD_MUTATION,
          variables: {
            file: files[0],
          },
          context: {
            hasUpload: true,
          },
        })
        this.isUploading = false
        FlashMessages.$emit('success', `Successfully uploaded file.`, { timeout: 1500 })
        this.$emit('update:uploadId', res.data.upload.id)
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    upload: {
      query: UPLOAD_QUERY,
      variables() {
        return {
          where: {
            id: this.uploadId,
          },
        }
      },
      skip() {
        return this.uploadId === undefined
      },
    },
  },
}
</script>
