<template>
  <ProductIconsMolecule>
    <ImageManagerOrganism
      :title="hasDarkIconOnly ? 'Icon' : 'Dark Theme Icon'"
      :uploadId="iconDarkImageId"
      :previewColor="theme.colors.white"
      :previewBackground="'rgb(1,8,20)'"
      @update:uploadId="$emit('update:iconDarkImageId', $event)"
    >
      <div class="icon-settings" slot="footer">
        <OnOffToggle
          :value="hasDarkIconOnly"
          @toggle="$emit('update:hasDarkIconOnly', !hasDarkIconOnly)"
          :label="'Use this icon for both dark and light theme'"
        />
      </div>
    </ImageManagerOrganism>
    <ImageManagerOrganism
      v-if="!hasDarkIconOnly"
      :title="'Light Theme Icon'"
      :uploadId="iconLightImageId"
      :previewColor="theme.colors.darkGrey"
      :previewBackground="'#eeedf3'"
      @update:uploadId="$emit('update:iconLightImageId', $event)"
    />
  </ProductIconsMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { OnOffToggle } from '@common/components'

import ImageManagerOrganism from '../Organisms/ImageManagerOrganism'

const ProductIconsMolecule = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  justify-content: center;
  grid-gap: 2rem;
  .span-2 {
    grid-column: span 2;
  }
  .uploader {
    background: ${({ theme }) => theme.colors.archonBlackTransparent};
    padding: 1rem;
    border-radius: 3px;
  }
  .icon-settings {
    padding: 0.5rem 0;
  }
`

export default {
  inject: ['theme'],
  props: {
    hasDarkIconOnly: {
      type: Boolean,
      required: true,
    },
    iconDarkImageId: {
      type: String,
    },
    iconLightImageId: {
      type: String,
    },
  },
  components: {
    ProductIconsMolecule,
    OnOffToggle,
    ImageManagerOrganism,
  },
}
</script>
