var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ImageManagerMolecule', {
    attrs: {
      "title": _vm.title,
      "upload": _vm.upload,
      "previewColor": _vm.previewColor,
      "previewBackground": _vm.previewBackground
    },
    on: {
      "file-selected": _vm.handleFileSelected,
      "select-upload": function selectUpload($event) {
        return _vm.$emit('update:uploadId', $event.id);
      }
    }
  }, [_vm._t("header", null, {
    "slot": "header"
  }), _vm._t("footer", null, {
    "slot": "footer"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }