var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ImageManagerMolecule', [_c('header', [_vm.hasHeaderSlot ? _vm._t("header") : _vm.showTitle ? _c('span', [_vm._v(_vm._s(_vm.title))]) : _vm._e()], 2), _c('main', [_c('ImagePreviewMolecule', {
    attrs: {
      "previewColor": _vm.previewColor,
      "previewBackground": _vm.previewBackground,
      "upload": _vm.upload
    }
  })], 1), _c('aside', [_c('IconButtonAtom', {
    on: {
      "click": _vm.triggerFileUpload
    }
  }, [_c('UploadCloudIcon'), _c('span', [_vm._v("upload")])], 1), _c('IconButtonAtom', {
    on: {
      "click": _vm.openGallery
    }
  }, [_c('DatabaseIcon'), _c('span', [_vm._v("gallery")])], 1)], 1), _c('input', {
    ref: "fileUpload",
    attrs: {
      "type": "file",
      "accept": "image/png, image/jpeg, image/svg+xml"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('file-selected', $event);
      }
    }
  }), _vm.hasFooterSlot ? _c('footer', [_vm._t("footer")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }