var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ImagePreviewMolecule', {
    attrs: {
      "previewBackground": _vm.previewBackground,
      "previewColor": _vm.previewColor
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [!_vm.hasPreviewImage ? _c('span', [_vm._v("no image set yet")]) : _c('ResponsiveImageAtom', {
    attrs: {
      "upload": _vm.upload,
      "expectedSizes": ['200px']
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }