<template>
  <ImageManagerMolecule>
    <header>
      <slot v-if="hasHeaderSlot" name="header" />
      <span v-else-if="showTitle">{{ title }}</span>
    </header>
    <main>
      <ImagePreviewMolecule :previewColor="previewColor" :previewBackground="previewBackground" :upload="upload" />
    </main>
    <aside>
      <IconButtonAtom @click="triggerFileUpload">
        <UploadCloudIcon />
        <span>upload</span>
      </IconButtonAtom>
      <IconButtonAtom @click="openGallery">
        <DatabaseIcon />
        <span>gallery</span>
      </IconButtonAtom>
    </aside>
    <input ref="fileUpload" type="file" accept="image/png, image/jpeg, image/svg+xml" @change="$emit('file-selected', $event)" />
    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </ImageManagerMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { UploadCloudIcon, DatabaseIcon } from 'vue-feather-icons'

import ImagePreviewMolecule from '@/components/Atomic/Molecules/ImagePreviewMolecule'
import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'

const ImageManagerMolecule = styled('div')`
  max-width: 350px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content 1fr;

  > header,
  > footer {
    grid-column: span 2;
    text-align: center;
  }

  > aside {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export default {
  props: {
    title: {
      type: String,
    },
    upload: {
      type: Object,
    },
    previewColor: {
      type: String,
      default: 'black',
    },
    previewBackground: {
      type: String,
      default: 'transparent',
    },
  },
  components: {
    ImageManagerMolecule,
    ImagePreviewMolecule,
    IconButtonAtom,
    UploadCloudIcon,
    DatabaseIcon,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    showTitle() {
      return !this.hasHeaderSlot && this.title !== undefined
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileUpload.click()
    },
    openGallery() {
      this.$root.$emit(
        'activateOverlay',
        'GalleryOverlay',
        {
          onSelectUpload: this.onSelectUpload,
          previewColor: this.previewColor,
          previewBackground: this.previewBackground,
          selectedUpload: this.upload,
        },
        this.onCloseOverlay,
      )
    },
    onSelectUpload($event) {
      this.$emit('select-upload', $event)
      this.$root.$emit('closeOverlay')
    },
    onCloseOverlay() {},
  },
}
</script>
