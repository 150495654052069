var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductIconsMolecule', [_c('ImageManagerOrganism', {
    attrs: {
      "title": _vm.hasDarkIconOnly ? 'Icon' : 'Dark Theme Icon',
      "uploadId": _vm.iconDarkImageId,
      "previewColor": _vm.theme.colors.white,
      "previewBackground": 'rgb(1,8,20)'
    },
    on: {
      "update:uploadId": function updateUploadId($event) {
        return _vm.$emit('update:iconDarkImageId', $event);
      }
    }
  }, [_c('div', {
    staticClass: "icon-settings",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('OnOffToggle', {
    attrs: {
      "value": _vm.hasDarkIconOnly,
      "label": 'Use this icon for both dark and light theme'
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.$emit('update:hasDarkIconOnly', !_vm.hasDarkIconOnly);
      }
    }
  })], 1)]), !_vm.hasDarkIconOnly ? _c('ImageManagerOrganism', {
    attrs: {
      "title": 'Light Theme Icon',
      "uploadId": _vm.iconLightImageId,
      "previewColor": _vm.theme.colors.darkGrey,
      "previewBackground": '#eeedf3'
    },
    on: {
      "update:uploadId": function updateUploadId($event) {
        return _vm.$emit('update:iconLightImageId', $event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }